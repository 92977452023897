<template>
  <div>
    <div v-if="OfficeManager" class="d-flex justify-content-end">
      <span class="icon-pencil text-primary cursor-pointer" @click="openModalEdit(data)" data-toggle="tooltip" :title="$t(module+'.tooltip.edit')"></span>
      <span v-if="!data.mo_product" class="icon-cancel-circle2 text-primary cursor-pointer cancel-icon" @click="openModalDelete(data)" data-toggle="tooltip" :title="$t(module+'.tooltip.delete')"></span>
    </div>
    <div class="row">
      <div class="col-lg-3">
        <img
        class="mw-100 w-auto cursor-pointer"
        :src="data.image.thumb"
        v-if="data.image"
        @click="openModal('product-image-modal')"
        />
        <el-modal id="product-image-modal" size="md">
          <img
          class="w-100"
          :src="data.image.full"
          v-if="data.image"
          />
        </el-modal>
    </div>
    <div class="col-lg-6">
      <h4>{{ data.name }}</h4>
      <div class="tags mb-2" v-if="data.tag">
        <span v-for="(tag, tagkey) in data.tag" :key="tagkey" class="d-inline-block text-grey mr-3">
          {{ getTagName(tag) }}
        </span>
      </div>
      <div>
        {{ data.description  }}
        <div class="mt-4 text-grey">
          <span class="d-inline-block mr-5">
            • {{ $t('product.seller', { name: data.fournisseur }) }}
          </span>
        </div>
      </div>
    </div>
    <div class="col-lg-3 text-center">
      <h2 class="m-0" v-if="data.price" v-html="currency(data.price, symbol(data.currency))"></h2>
      <div class="mb-1 text-center" v-if='OfficeManager'>
        <star-rating :show-rating="true" :increment="0.1" :max-rating ="5" :star-size="30" clas="icon-star-reting text-center" @rating-selected="retingSlected($event, data)" :rating="reting(data.users_votes)" ></star-rating>
        <div class="text-grey" v-if="data.votes > 0 && checkVote(data)">
          {{ $t(module+'.product.vote_moyenne', { nb: parseFloat(data.votes).toFixed(2) }) }}
        </div>
      </div>

      <div class="mb-1 text-center" v-if='!OfficeManager'>
        <star-rating :show-rating="true" :increment="0.1" :max-rating ="5" :star-size="30" :read-only="true" clas="icon-star-reting text-center" :rating="retingTotal(data.votes)" ></star-rating>
      </div>

      <div class="text-grey" v-if="(data.nomber_votes_initiale || data.nomber_votes) && reviews(data.nomber_votes_initiale, data.nomber_votes) > 0">
        {{ $t('product.reviews', { nb: reviews(data.nomber_votes_initiale, data.nomber_votes) }) }}
      </div>
      <div class="mt-2">
        <ProductOrderButton v-if="renderComponent && !OfficeManager" :id="data.id" :price="data.price"></ProductOrderButton>
        <a tag="button" :href="data.url" target="_blank" class="btn btn-primary text-white" v-if='OfficeManager'>
          {{ $t(module+'.product.order.achater') }}
        </a>
      </div>
      <div class="mt-2">
        <p @click="wishlist(data.id, typeActionOnwishList(data.id))" class="text-dark cursor-pointer" data-toggle="tooltip" :title="$t(module+'.tooltip.wishlist')">
          {{ $t(module+'.product.wishList.add') }}
          <span :class="typeActionOnwishList(data.id) == 'remove' ? 'icon-heart5 text-primary' : 'icon-heart6 text-primary'"></span>
        </p>
      </div>
    </div>
  </div>
  <div class="mb-4" v-if="data.rating && data.rating.title ">
    <h3 class="mt-0 mb-4">{{ data.rating.title }}</h3>
  </div>
    <el-chart-radar v-if="data.rating" :data="data.rating" ></el-chart-radar>
    <EditProduct v-if="OfficeManager" :data="elemEdit" @reloadChild="reloadChild"></EditProduct>
    <DeleteProduct v-if="OfficeManager" :data="elemDelete" @pushRouteBack="pushRouteBack"></DeleteProduct>
  </div>
</template>

<script>
/**
 * @group _ Module marketplace
 * This component for detail s product
 */
import {
WISHLIST,
UPDATE_PRODUCT
} from '../../mutations-types'
import ProductOrderButton from './_ProductOrderButton'
import EditProduct from './_EditProduct'
import DeleteProduct from './_DeleteProduct'
import StarRating from 'vue-star-rating'

export default {
  name: 'ProductDetails',
  props: ['data'],
  components: {
    ProductOrderButton: ProductOrderButton,
    EditProduct,
    DeleteProduct,
    StarRating
  },
  data () {
    return {
      module: 'modules.' + this.$route.meta.moduleName,
      elemEdit: {},
      elemDelete: {},
      renderComponent: true
    }
  },
  mounted () {
    let app = this
    // Tooltip
    this.$nextTick(function () {
      $('[data-toggle="tooltip"]').each(function () {
        if ($(this).attr('title') === '') {
          $(this).attr('title', $(this).next('.tooltip').html())
          $(this).next('.tooltip').remove()
        }
      }).tooltip({
        html: true,
        placement: 'bottom'
      }).each(function () {
        $(this).attr('title', '')
      })
    })
  },
  methods: {
    /**
     * @vuese
     * This function get tag list for product
     */
    getTagName (id) {
      return this.$store.state.marketplace.tags.find(item => item.id == id ).name
    },
    /**
     * @vuese
     * This function add to wishlist
     */
    wishlist (id, type) {
      this.$store.dispatch('marketplace/'+WISHLIST, {data: {id: id, type: type} , world: this.$store.getters['auth/getWorld'].id}).then(resp => {
      })
    },
    /**
     * @vuese
     * This function check if product on wishlist or not
     */
    typeActionOnwishList (id) {
      return (this.$store.state.marketplace.wishlist.length > 0 && this.$store.state.marketplace.wishlist.includes(id)) ? 'remove' : 'add'
    },
    /**
     * @vuese
     * This function open modal delete
     */
    openModalDelete(elem) {
      this.elemDelete = elem
      this.openModal('market-place-product-delete')
    },
    /**
     * @vuese
     * This function open modal edit
     */
    openModalEdit(elem) {
      this.elemEdit = elem
      this.openModal('market-place-product-edit')
    },
    pushRouteBack () {
      this.$router.go(-1)
    },
    reloadChild() {
      // Remove my-component from the DOM
      this.renderComponent = false;

      this.$nextTick(() => {
        // Add the component back in
        this.renderComponent = true;
      });
    },
    /**
     * @vuese
     * This function return vote for user
     */
    reting (list) {
      if(list == null)
        return 0

      let voteUser = list.find(item => item.user == this.userid)
      return typeof voteUser == 'undefined' ? 0 : voteUser.vote
    },
    /**
     * @vuese
     * This function return totla vote
     */
    retingTotal (nbr) {
      return nbr == null ? 0 : nbr
    },
    /**
     * @vuese
     * This function calcul new vote for product
     */
     retingSlected (e, product) {
       if(product.votes == 0 || product.votes == null) {
         product.nomber_votes_initiale = product.nomber_votes_initiale == null ? 0 :parseInt(product.nomber_votes_initiale)
         product.note_vote_initiale = product.note_vote_initiale == null ? 0 : parseInt(product.note_vote_initiale)

         let votes = ((product.nomber_votes_initiale * product.note_vote_initiale)+e)/(product.nomber_votes_initiale + 1)
         product.votes = votes
         product.nomber_votes = product.nomber_votes_initiale + 1
         product.users_votes = []
         product.users_votes.push({user: this.userid, vote: e})
       } else {
         if(product.users_votes == null) {
           product.users_votes = []
         }
         let findUser = product.users_votes.find(item => item.user == this.userid)
         if(typeof findUser == "undefined") {
           let votes = ((parseInt(product.nomber_votes) * parseInt(product.votes))+e)/(parseInt(product.nomber_votes) + 1)
           product.votes = votes
           product.nomber_votes = parseInt(product.nomber_votes) + 1
           product.users_votes.push({user: this.userid, vote: e})
         } else {
           let nbr_votes = product.nomber_votes_initiale == null ? 0 : parseInt(product.nomber_votes_initiale)
           let votes= product.note_vote_initiale == null ? 0 : parseInt(product.note_vote_initiale)

           product.users_votes.map((item, key) => {
             if(item.user == this.userid) {
               product.users_votes[key].vote = e
             }
             votes = ((nbr_votes * votes)+item.vote)/(nbr_votes + 1)
             nbr_votes = nbr_votes + 1
           })
           product.votes = votes
         }
       }
       this.$store.dispatch('marketplace/'+UPDATE_PRODUCT, {data: product , world: this.$store.getters['auth/getWorld'].id}).then(resp => {
       })
     },
    /**
     * @vuese
     * This function return symbol of currency in world or for product
     */
    symbol(id) {
      let symbol = null
      if(id == null) {
        id = this.$store.state.marketplace.world_currency
      }
      this.$store.state.marketplace.currency.map(item => {
        if(item.id == id) {
          symbol = item.symbol
        }
      })
      return symbol
    },
    /**
     * @vuese
     * This function count nomber users vote on product
     */
    reviews (initial, nbr) {
      return (nbr == null ? 0 : parseInt(nbr)) + (initial == null ? 0 : parseInt(initial))
    },
    /**
     * @vuese
     * This function return false if user connected is only person how vote
     */
    checkVote (product) {
      if(product.users_votes == null || product.users_votes.length == 0) {
        return false
      }
      let findUser = product.users_votes.find(item => item.user == this.userid)
      if(typeof findUser != "undefined" &&  product.users_votes.length == 1 ) {
        return false
      }
      return true
    }
  },
  computed: {
    /**
     * @vuese
     * This function return user id (User connectd)
     */
    userid() {
      return this.$store.state.auth.profile.id
    },
    /**
     * @vuese
     * This function sheck type user if is Office manager
     */
    OfficeManager() {
      return this.$store.getters['auth/isWorldOwner'];
    },
  }
}
</script>


<style scoped lang="scss">
.cancel-icon {
    margin-left: 15px;
}
</style>
<style lang="scss">
</style>
